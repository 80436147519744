import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";

const CallToAction = function ({ background, content }) {
  return (
    <section
      className="ttm-row bg-image-primary text-center"
      style={{
        backgroundImage: `url(${background.localFile.publicURL})`,
      }}
    >
      <Container>{parse(content)}</Container>
    </section>
  );
};

CallToAction.propTypes = {
  background: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default CallToAction;
