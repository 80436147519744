import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import * as s from "./ReferencesList.module.scss"

const ReferencesList = function ({ logos }) {
  return (
    <Slider
      dots={false}
      autoplay
      autoplaySpeed={5000}
      slidesToShow={5}
      slidesToScroll={5}
      responsive={[
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ]}
      className="mb-5"
    >
      {logos.map(({ id, localFile }) => {
        return (
          <div key={`reference-${id}`} className={s.slide}>
            <GatsbyImage
              alt={localFile.name}
              image={localFile.childImageSharp.gatsbyImageData}
              layout="constrained"
            />
          </div>
        )
      })}
    </Slider>
  )
}

ReferencesList.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      logos: PropTypes.oneOfType([PropTypes.object]).isRequired,
    }).isRequired
  ).isRequired,
}

export default ReferencesList
