import React from "react";
import PropTypes from "prop-types";
import { default as ReactSlick } from "react-slick";
import parse from "html-react-parser";

import * as s from "./Slider.module.scss";

const Slider = function ({ data }) {
  return (
    <ReactSlick
      dots
      autoplay
      autoplaySpeed={3000}
      className={`${s.slider} py-0`}
    >
      {data.map(({ id, background, content }) => (
        <div key={`slide-${id}`}>
          <div
            className={s.slide}
            style={{
              backgroundImage: `url(${background.localFile.publicURL})`,
            }}
          >
            <div className={s.content}>{parse(content.data.content)}</div>
          </div>
        </div>
      ))}
    </ReactSlick>
  );
};

Slider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      background: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Slider;
