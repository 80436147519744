import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import * as s from "./SoonerPlayer.module.scss";

const SoonerPlayer = function ({ audioFile }) {
  const [height, setHeight] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [context, setContext] = useState(false);
  const [source, setSource] = useState(false);

  const audioRef = useRef();
  const analyzer = useRef();
  const dataArray = useRef();
  const raf = useRef();

  useEffect(() => {
    const initialHeightState = [40, 30, 70, 45, 83, 40];
    const update = () => {
      analyzer.current.getByteFrequencyData(dataArray.current);
      const currentDataArray = dataArray.current;
      setHeight([
        (currentDataArray[24] * 0.42) / 2,
        (currentDataArray[48] * 0.45) / 2,
        (currentDataArray[64] * 0.85) / 2,
        (currentDataArray[128] * 0.7) / 2,
        (currentDataArray[192] * 1) / 2,
        (currentDataArray[256] * 0.65) / 2,
      ]);
      raf.current = requestAnimationFrame(update);
    };

    if (isPlaying) {
      let audioContext = null;
      let audioSource = null;
      let ctx = null;
      let src = null;

      if (!context && !source) {
        // intialize audioContext
        audioContext =
          new window.AudioContext() || new window.webkitAudioContext(); // eslint-disable-line new-cap
        setContext(audioContext);

        ctx = context || audioContext;

        // initialize audioSource
        audioSource = ctx.createMediaElementSource(audioRef.current);
        setSource(audioSource);

        ctx = context || audioContext;
        src = source || audioSource;

        // intialize analyser
        analyzer.current = ctx.createAnalyser();

        // connect source to analyser
        src.connect(analyzer.current);

        // analyser should output to the audio device
        analyzer.current.connect(ctx.destination);
        analyzer.current.smoothingTimeConstant = 0.79;
        analyzer.current.fftSize = 1024;
      }

      // array of audio data
      dataArray.current = new Uint8Array(analyzer.current.frequencyBinCount);

      audioRef.current.play();
      raf.current = requestAnimationFrame(update);
    } else {
      audioRef.current.pause();
      setHeight(initialHeightState);
      cancelAnimationFrame(raf.current);
    }
  }, [isPlaying, context, source]);

  const onTogglePlay = () => {
    if (!audioRef.current) return;
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <button
        type="button"
        className="btn btn-light shadow-none me-5"
        onClick={onTogglePlay}
      >
        <i
          className={`fas ${
            isPlaying ? "fa-pause" : "fa-play"
          } fa-2x text-primary`}
          style={{ width: "0.5em" }}
        />
      </button>
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        src={audioFile}
        onEnded={() => setIsPlaying(false)}
      />
      {/* eslint-enable jsx-a11y/media-has-caption */}
      <button
        type="button"
        className={`d-flex align-items-center ${s.bars}`}
        onClick={onTogglePlay}
      >
        <span className={s.bar1} style={{ height: height[0] }} />
        <span className={s.bar2} style={{ height: height[1] }} />
        <span className={s.bar3} style={{ height: height[2] }} />
        <span className={s.bar4} style={{ height: height[3] }} />
        <span className={s.bar5} style={{ height: height[4] }} />
        <span className={s.bar6} style={{ height: height[5] }} />
      </button>
    </div>
  );
};

SoonerPlayer.propTypes = {
  audioFile: PropTypes.string.isRequired,
};

export default SoonerPlayer;
