import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Slider from "../components/Slider";
import ImgFading from "../components/ImgFading";
import SoonerPlayer from "../components/SoonerPlayer";
import ServicesList from "../components/ServicesList";
import CallToAction from "../components/CallToAction";
import ReferencesList from "../components/ReferencesList";

const HomeTemplate = function ({ data: { appSetting, homepage, services } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Slider data={homepage.slider} />

      <section className="ttm-row">
        <Container>
          <Row>
            <Col lg={6} className="text-center text-lg-start mb-3 mb-lg-0">
              <GatsbyImage
                image={
                  homepage.newsImage.localFile.childImageSharp.gatsbyImageData
                }
                alt={homepage.newsImage.localFile.name}
              />
            </Col>
            <Col lg={6}>
              <header className="mt-3">
                <h5>{homepage.newsLabel}</h5>
                <h2>{homepage.newsTitle}</h2>
              </header>
              {parse(homepage.newsContent.data.newsContent)}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="ttm-row bg-image-gray">
        <Container>
          <Row>
            <Col className="mx-auto mb-4">
              <header className="text-center">
                <h5>{homepage.servicesLabel}</h5>
                <h2>{homepage.servicesTitle}</h2>
              </header>
            </Col>
          </Row>
          <ServicesList list={services.nodes} />
        </Container>
      </section>

      <section className="ttm-row">
        <Container>
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              <header className="mt-3">
                <h5>{homepage.aboutUsLabel}</h5>
                <h2>{homepage.aboutUsTitle}</h2>
              </header>
              {parse(homepage.aboutUsContent.data.aboutUsContent)}
              {homepage.aboutUsSong && (
                <SoonerPlayer
                  audioFile={homepage.aboutUsSong.localFile.publicURL}
                />
              )}
            </Col>
            <Col lg={6} className="text-center text-lg-end mb-3 mb-lg-0">
              <ImgFading>
                {homepage.aboutUsImages.map(({ id, localFile }) => (
                  <GatsbyImage
                    key={`aboutus-${id}`}
                    className="rounded shadow"
                    image={localFile.childImageSharp.gatsbyImageData}
                    alt={localFile.name}
                  />
                ))}
              </ImgFading>
            </Col>
          </Row>
        </Container>
      </section>

      <CallToAction
        background={appSetting.callToActionBackground}
        content={appSetting.callToActionContent.data.callToActionContent}
      />

      <section className="ttm-row">
        <Container>
          <Row>
            <Col className="mx-auto">
              <header className="text-center mb-5">
                <h5>{homepage.referencesLabel}</h5>
                <h2>{homepage.referencesTitle}</h2>
              </header>
              <ReferencesList logos={homepage.referencesLogos} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

HomeTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default HomeTemplate;

export const Head = ({
  location,
  data: {
    homepage: { title, description, localizations },
  },
}) => (
  <Seo
    title={title}
    description={description}
    localizations={localizations.data}
    isHome
    pathname={location.pathname}
  />
);

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    homepage: strapiHomepage(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
          }
        }
      }
      title
      description
      slider {
        background {
          localFile {
            publicURL
          }
        }
        content {
          data {
            content
          }
        }
      }
      newsTitle
      newsLabel
      newsContent {
        data {
          newsContent
        }
      }
      newsImage {
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      servicesLabel
      servicesTitle
      aboutUsLabel
      aboutUsTitle
      aboutUsContent {
        data {
          aboutUsContent
        }
      }
      aboutUsSong {
        localFile {
          publicURL
        }
      }
      aboutUsImages {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      referencesLabel
      referencesTitle
      referencesLogos {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    services: allStrapiService(
      filter: { locale: { eq: $locale } }
      sort: { serviceCard: { order: ASC } }
    ) {
      nodes {
        fields {
          slug
        }
        serviceCard {
          id
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          description
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      callToActionBackground {
        localFile {
          publicURL
        }
      }
      callToActionContent {
        data {
          callToActionContent
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
