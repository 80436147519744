import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

const ServicesList = function ({ list }) {
  return (
    <Row>
      {list.map(
        ({
          fields: { slug },
          serviceCard: { id, icon, title, description },
        }) => (
          <Col sm={6} lg={3} key={`service-${id}`} className="mb-4">
            <aside className="card">
              <div className="card-body text-center">
                <GatsbyImage
                  className="mb-3"
                  alt={title}
                  image={icon.localFile.childImageSharp.gatsbyImageData}
                />
                <h5 className="card-title">
                  <Link className="stretched-link" to={slug}>
                    {title}
                  </Link>
                </h5>
                <p className="card-text">{description}</p>
              </div>
            </aside>
          </Col>
        )
      )}
    </Row>
  );
};

ServicesList.propTypes = {
  list: PropTypes.arrayOf({
    slug: PropTypes.string.isRequired,
    serviceCard: PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ServicesList;
